@import '../../abstracts/_mixins';
@import '../../abstracts/_variables';
@import '../../components/title';
@import '../../components/button';


#newsLetterBlock {
    background-color: var(--toc-grey);
}

.news-letter {
    gap: 3rem;
    padding: 3rem 0;

    &__title {
        @include doubleTitle();
        //override mixin
        p {
            span {
                @include flex-column-justify(center);
                text-align: center;
            }
        }
    }

    &__social__icons {
        &__list {
            @include flex-row-justify(center);

            >li {
                margin-right: 1rem
            }

            & img {
                width: 6.5rem;
            }
        }
    }

    &__form {
        &__action {
            @include flex-row-justify(center);
            margin: auto;

            &__group {
                display: grid;
                grid-auto-flow: column;
                grid-template-columns: minmax(240px, 700px) minmax(113px, 311px);
                grid-template-rows: 42px;
                margin: 0;

                &>label {
                    display: flex;
                    margin-bottom: inherit;
                }

                &__email {
                    display: block;
                    width: 100%;
                    height: auto;
                    padding: 0.375rem 0.75rem;
                    font-size: 1.2rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: var(--toc-light-brown);
                    background-color: var(--toc-white);
                    background-clip: padding-box;
                    border: none;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    border-top-left-radius: 50px;
                    border-bottom-left-radius: 50px;
                    outline: none;
                    box-shadow: -1px 6px 9px 3px #00000021;
                    -webkit-box-shadow: -1px 6px 9px 3px #00000021;
                    -moz-box-shadow: -1px 6px 9px 3px #00000021;
                }

                &--button {
                    @include color(--toc-white, --toc-noug);
                    @include button-cta(noug);
                    box-shadow: 2px 6px 9px 3px #00000021;

                    padding: 0;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;

                    &>#newsLetterSubmitText::after {
                        content: attr(data-desktop);
                    }
                }
            }
        }
    }

    &__text {
        &.paragraphe {
            margin: auto;
            color: var(--toc-dark-grey);
            text-align: center;
        }
    }

    /* ***************************** RESPONSIVE ***************************** */
    @media (max-width: $breakpoint-lg) {
        &__title {
            @include title();

            //override mixin
            h2 {
                span {
                    &::before {
                        display: none
                    }

                    small {
                        padding: 0
                    }
                }
            }
        }

        &__social__icons {
            &__list {
                @include flex-row-justify(center);

                & img {
                    width: 4rem;
                }
            }
        }

        #newsLetterSubmitText::after {
            content: attr(data-mobile);
        }
    }
}